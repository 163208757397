<template>
    <div style="position:relative;"  class="crane-and-rigging">
        <div class="container landing-banner-container" style="padding-top: 7.5rem">
                <div class="left-banner">
                    <h1 style="font-size: 3.625rem;font-weight: bold;line-height: 1.21;color: #021222;padding-bottom: 1.875rem;">Effective <span style="color:#00c8d8;">Jobsite Management </span>with Record TIME</h1>
                <div>
                 <p>Are you tired of the complexities involved in managing different jobsites? Look no further, than Record TIME. Record TIME is a construction app, equipped with an advanced jobsite management feature. It empowers you to create specific jobsites based on location or project type. Indeed, our all-in-one solution is designed to simplify the process of creating jobsite locations, managing visits, and tracking attendance. In addition, you can allocate manpower, equipment, and materials with precision, ensuring optimal utilisation and cost-effectiveness. Furthermore, Record TIME provides a centralised platform for effective jobsite communication. It enables seamless real time collaboration among construction team members, helping the project manager stay on top of every detail and accelerate decision.
 <br><br>
                </p>
                </div>
                <h3>Enhance Efficiency, Improve Communication, Track Progress and Ensure Accurate Automated Timesheets, All With Just a Few Clicks.</h3>
                <br><br>
                    <v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
                        @click="redirectToLink('https://recordtime.com.au/geotechnical-engineer-app')" class="mr-7 btn-1" elevation="0">
                        Read More
                    </v-btn>
            <v-btn height="56" class="get-demo-btn"  color="white" @click="getDemo()">
                Get A Demo Now
            </v-btn>
            </div>
            <div  class="right-banner" style="text-align: right;">
                <img :src="`${publicPath}assets/features-image/job-site-manager.webp`" alt="jobsite management using record time for smooth operation"  style="max-width: 90%;">
            </div>
            <div style="clear: both;"></div>
        </div>
        <v-container>
            <div class="gray-container">
                <h3>Make Your Jobsite More Proactive and Safer</h3>
                <img :src="`${publicPath}assets/features-image/benefits-of-construction-software.webp`" alt="Benefits of jobsite management software" style="margin-top: 20px; object-fit: contain;width: 100%;">
                </div>
             <br><br>
            <div class="left-blog1">
                <h2>Optimising Jobsite Management: Reduce Risk and Increase Productivity</h2>
                <h3>Utilising Geofencing to Improve Safety Measures</h3><br/>
                <p>Record TIME will take your jobsite management process to the next level with its geofencing option. Record TIME's geofencing capability allows users to create virtual fences around their job sites, ensuring that users accurately track attendance and activity within designated areas.
                    <br><br>In addition, you can stay updated with automatic notifications when someone enters or exits the virtual boundaries of a job site. This feature adds an extra layer of security and helps maintain a safe and controlled environment, which is crucial for effective construction management.

 
                    <br><br>Geofencing, in particular, provides valuable insights into the time spent by individuals within specific job sites. Consequently, this data can help you optimise workflows, allocate resources effectively, and make informed decisions to improve overall <a href="https://recordtime.com.au/blog/best-construction-scheduling-software">construction project management</a> efficiency.
               </p>
                </div>
            <div class="right-blog1 ">
                <img :src="`${publicPath}assets/features-image/job-site-management-with-record-time.webp`" alt="boost productivity with better jobsite management">
            </div>
            <div style="clear:both;"></div>
            <br>
            <h2>Visitor Management on the Jobsite</h2>
            <p>Visitor management is an important aspect of construction sites for ensuring safety and security. With our jobsite management software, site managers can effortlessly track visits, ensuring accurate and up-to-date project information on who is present at your construction site. The sign-in and sign-out functionality simplifies jobsite management, making attendance tracking easier and providing a seamless experience for both your project team and site visitors.</p>
 
            <h2>Actionable Report Generation</h2>
            <p>Finally, generate comprehensive reports to gain valuable insights into your job site. Our management software provides detailed analytics, enabling site manager to track attendance, monitor daily reports, and evaluate resource allocation in real time. These reports are essential for construction jobsite management as it offers actionable data to optimise your construction industry processes and make informed decisions.</p>
            
            <p><b>Say goodbye to paperwork, manual tracking, and communication gaps with our business management solutions software. Your job site is in safe hands with our jobsite management solutions which has the ability to assign tasks for unlimited projects.

</b></p>
          </v-container>
          <get-demo-modal v-if="getDemoModal" :demoEmail="demoEmail" @excEmit="excEmit" @getDemoModalClose="getDemoModalClose" @demoResponse="demoResponse" url="https://www.youtube.com/embed/62kV7CIU_F4"/>

    </div>

</template>
<style lang="scss">
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
        }
    }
    .left-banner{
        width: 45%; 
        float: left;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .right-banner{
        width: 55%; 
        float: right;
        padding: 0px 20px 20px;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
            h1{ font-size: 40px !important;}
        }
    }
    .heavy-civil{
        .banner-img{
            @media only screen and (max-width: 600px) {
            position: relative !important;
            }

            @media only screen and (max-width: 1280px) {
                width: 100% !important;
                top: 20px !important;
                height: auto !important;
            }
        }
        p{
            @media only screen and (max-width: 1280px) {
                padding-bottom: 0px !important;
            }
        }
    }
    .banner-img{
        @media only screen and (max-width: 600px) {
            position: relative !important;
        }

        @media only screen and (max-width: 1280px) {
            position: relative !important;
        }
    }
    .gray-container{
        background: #F8F9FB;
        padding: 20px;
        margin-top: 30px;
        h3 {
            font-size: 32px;
            font-weight: bold;
            text-align: center;
            color: $black-pearl;
            margin-bottom: 15px;

            span {
            color: $faux-dark-turquoise;
            }
        }
        p{
            margin-top: 40px;
            text-align: center;
            color: #000;
        }
        .list{
            h1{
                color: #00C8D8;
            }
            p{
                color: #000;
                text-align: left;
            }
        }
    }
    .left-blog1{
      float:left;
      max-width: calc(100% - 600px);
      padding-right: 15px;
      @media only screen and (max-width: 1280px) {
        max-width: 100%;
      }
    }
    .right-blog1{
      width: 600px;
      float:right;
      @media only screen and (max-width: 1280px) {
        width: 100%;
      }
      img{
        max-width: 600px;
        width: 100%;
        @media only screen and (max-width: 1280px) {
            max-width: 100%;
        }
      }
    }
    @media only screen and (max-width: 600px) {
      .left-blog1{
        float: none;
        max-width: 100%;
        padding-right: 0px;
      }
      .right-blog1{
        float: none;
        max-width: 100%;
      }
    }
</style>
<script>
import GetDemoModal from '@/components/GetDemoModal.vue';
export default{
    metaInfo: {
    title: 'Jobsite Management for Construction Business | Record TIME',
    // titleTemplate: 'Record TIME → %s',
    meta: [
      {name: 'name', content: ''},
      {name: 'description', content: 'Jobsite management software to simplify and optimise your construction. With Record TIME you can upgrade and manage jobsite effectively.'},
      {name: 'keyword', content: 'jobsite management'}
      ],
    link: [
      { rel: 'canonical', href: 'https://recordtime.com.au/jobsite-management-software' }
    
    ]
  },
  components: {
        GetDemoModal
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            getDemoModal: false,
        }
    },
    methods: {
        redirectToLink(url) {
        window.open(url, '_blank');
        },

        getDemoModalClose(){
            this.getDemoModal = false;
            this.demoEmail = '';
        },
        getDemo(){
            this.getDemoModal = true;
        }
    }
}
</script>
